import _emitterify from "utilise/emitterify";
import _key from "utilise/key";
import _set from "utilise/set";
import _is from "utilise/is";
import _log from "utilise/log";
import _err from "utilise/err";
import _deb from "utilise/deb";
import _file from "utilise/file";
import _update from "utilise/update";
import _browserify from "browserify";
import _findPackageJson from "find-package-json";
import _path from "path";
import _browserResolve from "browser-resolve";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// -------------------------------------------
// Synchronises resources between server/client
// -------------------------------------------
exports = function (ripple, {} = {}) {
  // TODO: prepopulate from browser
  log("creating");

  const from = async ({
    data
  }) => {
    if (data.type != "SUBSCRIBE") return req;
    const module = data.value,
          npm = ripple("npm");
    if (!(module in npm.modules)) update(`modules.${module}`, await bundle(module))(npm); // return stream(subset(`modules.${module}`, npm))

    return stream(subset(`modules.${module}`, npm)).map(() => ({
      exec: (o, {
        module,
        bundle
      }) => {
        const m = {};
        new Function("module", "exports", bundle)(m, {});
        o.next({
          type: "update",
          key: module,
          value: m.exports
        });
      },
      value: {
        module,
        bundle: npm.modules[module]
      }
    })).unpromise();
  };

  ripple("npm", {
    modules: {}
  }, {
    from
  });
  return ripple;
};

const bundle = module => new Promise((resolve, reject) => browserify(bresolve(module), {
  standalone: module
}).bundle((err, buf) => err ? reject(err) : resolve(buf.toString())));

const emitterify = _emitterify;
const key = _key;
const set = _set;
const is = _is;

const subset = (k, {
  destroy = true
} = {}) => input => {
  if (!is.def(k)) return input;
  const output = key(k, key(k)(input))(emitterify());
  input.on("change").filter(({
    key = ""
  }) => ~key.indexOf(k)).map(change => set(change)(output)).until(output.once("stop"));
  output.once("stop").filter(d => destroy).map(d => input.emit("stop"));
  return output;
};

const stream = (input, {
  destroy = true,
  id
} = {}) => emitterify(input).on("value").on("start", function () {
  this.next({
    type: "update",
    value: input
  });
  input.on("change").map((this || _global).next).until(this.once("stop"));
  this.once("stop").filter(d => destroy).map(d => input.emit("stop"));
});

const log = _log("[ri/npm]"),
      err = _err("[ri/npm]"),
      deb = _deb("[ri/npm]"),
      file = _file,
      update = _update,
      browserify = _browserify,
      {
  browser = {}
} = _findPackageJson().next().value,
      {
  resolve
} = _path,
      bresolve = module => _browserResolve.sync(module, {
  filename: resolve(".", "./foo")
});

export default exports;